/* < GLOBAL VARIABLES */
:root {
  --bg_white: #e7e7e7;
  --bg_grey: #f8f8f8;
  --txt_black: #000000;
  --txt_white: #e7e7e7;
  --border_grey: #767676;
  --txt_grey: #767676;
  --txt_grey_light: #999999;
  --icon_not_selected: #4d4d4d;
  --btn_card: #d5d5d5;
  --btn_card_light: #eeeeee;
  --btn_bg: #000000;
  --btn_text: #e7e7e7;
  --btn_header: #eeeeee;
  --border_grey_transparent: rgba(118, 118, 118, 0.2);
  --DM_icon_bg: #dfdfdf;
  --DM_icon: #e7e7e7;
  // Dark Mode
  --DM_icon_bg_dark: #333333;
  --DM_icon_dark: #e7e7e7;
  --DM_bg_header_dark: #000;
  --DM_bg_footer_dark: #000;
  --DM_bg_dark: #111;
  --DM_bg_lighter: rgb(67, 67, 67);
}
// https://rgbacolorpicker.com/hex-to-rgba
/* > GLOBAL VARIABLES */

/* < GENERAL SETTINGS */
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: var(--bg_grey);
  color: var(--txt_grey);
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--txt_black);
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page-header-btn {
  background-color: var(--btn_header);
  padding: 5px 8px;
  width: auto;
  color: var(--txt_black);
}
.page-subheader {
  margin: 4vh 0 3vh 0;
  font-size: 30px;
  font-weight: 800;
  color: var(--txt_black);
}
/* > GENERAL SETTINGS */

/* < MODAL */
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
// /* Modal Content/Box */
.modal-content {
  background-color: var(--bg_white);
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid var(--border_grey);
  width: 80%; /* Could be more or less, depending on screen size */
  .card-text {
    .tags-container {
      .tag {
        padding-right: 10px;
      }
      .before::before {
        content: " | ";
        font-weight: bold;
      }
    }
  }
}
/* The Close Button */
.close {
  color: var(--border_grey);
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: var(--txt_black);
  text-decoration: none;
  cursor: pointer;
}
.card-body-main {
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
  margin-bottom: 30px;
  img {
    max-width: 47%;
    height: auto;
  }
}
/* > MODAL */

/* < MENUS SETTINGS */
.nav-bar {
  .logo-menu {
    ul {
      background-color: var(--bg_white);
      min-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2vh 5vw;
      #logo {
        display: flex;
        align-items: center;
      }
      .logo {
        font-size: 1.6rem;
        b {
          font-weight: 900;
        }
      }
      .right-side {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .theme-icon {
        width: 23px;
        background-color: var(--DM_icon_bg);
        padding: 7px;
        border-radius: 50%;
      }
      .flag-icon {
        width: 30px;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }
}

.lateral-btn {
  position: fixed;
  border-bottom: 2px solid var(--DM_icon);
  top: 80px;
  right: -20px;
  .flag-icon {
    position: absolute;
    right: 20px;
  }
  .hide {
    opacity: 0;
  }
}
.lateral-btn:hover {
  right: 0;
  .flag-icon {
    position: relative;
    right: 0;
    opacity: 100;
  }
}

.footer {
  background-color: var(--bg_white);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  .logo-footer {
    display: none;
  }
  ul {
    min-height: 20px;
    padding: 1.2vh 4vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    li {
      padding: 0;
      font-size: 0.85rem;
      text-align: center;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .menu-icon {
        min-width: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        margin-right: 0px;
      }
    }
  }
}
/* > MENUS SETTINGS */

/* < MAIN AREA SETTINGS */
.main-section {
  flex: 1;
  padding: 20px 20px;
  margin-bottom: 40px;
}
/* < HOME */
.homepage {
  width: 100%;
  min-height: 75vh;
  clear: both;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10vh;
  justify-content: center;

  .home_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    min-width: 200px;
    min-height: 200px;
    position: relative;
    border-radius: 100%;
    margin-bottom: 20px;
    .image {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      animation: morph 8s ease-in-out infinite 1s;
      background-blend-mode: multiply;
      box-shadow: inset 0px 0px 0px 9px var(--border_grey_transparent);
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
      overflow: hidden;
    }
  }
  .details {
    /* margin-left: 80px; */
    margin: 0 20px;
    text-align: center;
    .name {
      font-size: 35px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 19px;
    }
    .job-text,
    .job {
      font-weight: 300;
      max-width: 450px;
      margin-bottom: 10px;
    }
    .job-text {
      font-style: italic;
    }
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
/* > HOME */

/* < LOADER */
.loader {
  padding-top: 100px;
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--DM_bg_dark);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
/* > LOADER */
/* < PORTFOLIO */
.main-section .portfolio-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: start;
}
.main-section .portfolio-filter li {
  margin: 0 15px;
  cursor: pointer;
}
.main-section .portfolio-filter li:hover {
  font-weight: bolder;
}
.cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 5px;
}
/* > PORTFOLIO */

/* < ABOUT */
.about {
  .about-image {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    margin: 30px 0 48px 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    img {
      position: relative;
      left: 50%;
      width: 40vw;
    }
  }

  .about_title {
    padding-bottom: 20px;
    border-bottom: solid 1px var(--border_grey);
    .name-subtitle {
      font-style: italic;
    }
  }
  .about_description {
    padding: 20px 0 50px 0;
  }
  .about_skills {
    margin-bottom: 50px;
    .skills_title {
      margin-bottom: 10px;
    }
    .about_skill_list {
      li {
        margin-bottom: 5px;
      }
      .arrow-svg {
        width: 10px;
        padding-right: 5px;
      }
    }
    .title {
      padding-bottom: 10px;
    }
  }
}
/* > ABOUT */

/* > LEARN */
.learn {
  .cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }
  .card-body {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    .card-btn {
      display: none;
    }
  }
}
/* > LEARN */

/* < CONTACT */
.form-section {
  margin-top: 5vh;
  font-size: 1.2rem;
  form {
    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      input,
      textarea {
        border: solid 1px var(--txt_grey_light);
        margin-top: 5px;
      }
    }
    button {
      font-size: 1rem;
      background-color: var(--btn_bg);
      color: var(--btn_text);
      padding: 10px 15px;
      &:hover {
        background-color: var(--btn_card_light);
        color: var(--btn_bg);
        cursor: pointer;
      }
    }
  }
  .form-message {
    color: green;
    font-style: italic;
    margin-top: 20px;
  }
  .form-error-message {
    color: red;
    font-style: italic;
    margin-top: 20px;
  }
}
/* < CONTACT */

/* > MAIN AREA SETTINGS */

/* < CARD SETTINGS */
.card-body {
  margin: 3vh 0;
  border: solid 2px var(--border_grey_transparent);
  .card-text {
    padding: 15px 20px;
  }
  .card-image {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
  }
  .card-btn {
    .card-btn-link {
      margin-top: 30px;
      background-color: var(--btn_card);
      padding: 5px 8px;
      border-radius: 3px;
      transition: 0.1s all;
    }
    .card-btn-link:hover {
      background-color: var(--btn_card_light);
      color: rgb(59, 59, 59);
      cursor: pointer;
      .card-btn-txt {
        display: inline;
        margin-right: 10px;
      }
    }
    .card-btn-txt {
      display: none;
    }
  }
  .card-btn:active {
    transform: scale(0.98);
  }
  .card-text-content {
    margin-bottom: 30px;
    text-align: justify;
  }
  .card-tag-name {
    margin-right: 10px;
  }
  .button-icon {
    max-width: 16px;
    max-height: 16px;
  }
}
/* > CARD SETTINGS */

/* < TOOLS */
.tools-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .tool-card {
    width: 100%;
    cursor: pointer;
    .tool-icon-container {
      text-align: center;
      width: 100%;
      .tool-icon {
        width: 100px;
        padding-bottom: 15px;
      }
    }
    .tool-card-content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: var(--bg_white);

      border-radius: 5px;
      padding: 20px;
      .menu-icon-wrapper {
        display: flex;
        flex-direction: column;
        .tool-name {
          text-align: center;
          margin-bottom: 15px;
          padding-bottom: 5px;
          border-bottom: solid 1px var(--border_grey);
        }

        .tool-description {
          //text-align: left;
        }
      }
    }
  }
}
/* > TOOLS */
.tool-page{
  .card-body{
    padding: 20px 10px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-image {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }
  a {
    font-size: 1rem;
    background-color: var(--btn_text);
    color: var(--btn_bg) !important;
    padding: 10px 15px;
    &:hover {
      background-color: var(--btn_bg);
      border: solid 1px var(--bg_white);
      color: var(--btn_text) !important;
      cursor: pointer;
    }
  }
  form{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    label{
      font-weight: bold;
    }
  }
  .qr-codes-wrapper{
    padding: 20px;
    display: flex;
    gap: 20px;
  }
}
/* < TOOLS

/* DARK THEME */
body.dark {
  color: var(--DM_white);
  background-color: var(--DM_icon_bg_dark);
  a {
    text-decoration: none;
    color: var(--txt_grey_light);
  }
  .image {
    box-shadow: inset 0px 0px 0px 9px var(--DM_white_transparent);
  }
  .nav-bar {
    .logo-menu {
      ul {
        background-color: var(--DM_bg_dark);
        .theme-icon {
          background-color: var(--DM_icon_bg_dark);
        }
        .logo {
          color: var(--txt_white);
        }
      }
    }
  }
  .page-header-btn {
    background-color: var(--DM_bg_dark);
    color: var(--txt_white);
  }

  .page-subheader,
  .homepage,
  .portfolio-filter {
    color: var(--txt_white);
  }
  .modal {
    .modal-content {
      background-color: var(--txt_black);
    }
  }
  .loader {
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--txt_white);
      margin: -4px 0 0 -4px;
    }
  }
  .form-section {
    color: var(--txt_white);
  }
  .about {
    color: var(--txt_white);
    .about-image {
      background-color: var(--DM_bg_lighter);
    }
    .arrow-svg {
      height: 15px;
      padding-right: 55px;
    }
  }
  .card-body {
    color: var(--txt_white);
    background-color: var(--DM_bg_dark);
  }
  .tool-card {
    .tool-card-content {
      background-color: var(--DM_bg_lighter);
    }
  }
  .footer {
    background-color: var(--DM_bg_footer_dark);
    span {
      color: var(--txt_grey_light);
    }
    .menu-icon {
      background-color: white;
      padding: 5px;
      border-radius: 50%;
    }
  }
  .coming-soon{
    h2{
      color: var(--DM_icon);
    }
  }
}
/* DARK THEME */

/* > MEDIA QUERIES */
@media only screen and (min-width: 320px) {
  .tools-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .tool-card {
      width: 47%;
    }
  }
}

@media only screen and (max-width: 425px) {
  .footer ul li {
    font-size: 0.8rem;
  }
  .menu-icon {
    max-width: 20px;
    max-height: 20px;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) {
  .App {
    flex-direction: row-reverse;
  }
  .logo {
    display: none;
  }
  .nav-bar {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  .nav-bar {
    .logo-menu {
      ul {
        background-color: transparent;
      }
    }
  }
  .main-section {
    padding: 50px 10px;
    text-align: left;
    margin-left: 350px;
  }
  .homepage {
    margin-left: 350px;
    margin-top: 20vh;
    margin-bottom: 0;
  }
  .avatar {
    width: 300px;
    height: 300px;
  }
  .card-body {
    border: solid 2px var(--border_grey);
    .card-body-main {
      padding: 10px;
      max-width: 100%;
      margin-bottom: 30px;
      img {
        width: 50%;
        height: 100%;
      }
    }
  }
  .about {
    .about-image {
      img {
        position: relative;
        left: 50%;
        width: 20vw;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 250px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25vh 0 0 65px;
    .logo-footer {
      display: block;
      margin-bottom: 40px;
      font-size: 1.8rem;
      b {
        font-weight: 900;
      }
    }
    ul {
      padding: 0;
      display: flex;
      gap: 20px;
      flex-direction: column;
      text-align: left;
      li {
        padding: 0;
        font-size: 1rem;
        text-align: left;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        span {
          padding-left: 12px;
        }
        .link-name {
          display: block;
        }
      }
    }
  }
  .tool-card {
    width: 20%;
    .menu-icon-wrapper {
      display: flex;
      // align-items: flex-start;
      align-items: center;
    }
  }
  .form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-contact {
      width: 50vw;
    }
  }
  body.dark {
    .logo-menu {
      ul {
        background-color: transparent !important;
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
  }
  .modal-content {
    width: 60%;
  }
  .cards-wrapper .card-body {
    width: 45%;
  }
  .card-body-main {
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
      width: 60% !important;
    }
    .card-text {
      width: 35%;
      pre {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 1440px) {
  .cards-wrapper .card-body {
    width: 30%;
  }
}
@media only screen and (max-height: 360px) {
  .footer {
    padding: 10vh 0 0 65px;
    ul {
      gap: 10px;
    }
  }
}
/* < MEDIA QUERIES */

/* < AUXILIAR CLASSES */
.m-top-0 {
  margin-top: 1vh;
}
.m-top-0-5 {
  margin-top: 0.5vh;
}
.m-top-1 {
  margin-top: 1vh;
}
.m-top-2 {
  margin-top: 2vh;
}
.m-top-3 {
  margin-top: 3vh;
}
.m-top-4 {
  margin-top: 4vh;
}
/* > AUXILIAR CLASSES */

/* < COMING SOON */
.coming-soon {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--icon_not_selected);
    color: var(--icon_not_selected);
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--icon_not_selected);
    color: var(--icon_not_selected);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--icon_not_selected);
    color: var(--icon_not_selected);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: var(--icon_not_selected);
    }
    50%,
    100% {
      background-color: #4d4d4d28;
    }
  }
}
/* > COMING SOON */
